import iAnalytics from "./iAnalytics";
import mixpanel from "mixpanel-browser";

export default function Mixpanel(token: string): iAnalytics {
  mixpanel.init(token);

  return {
    identify: (id: string) => {
      mixpanel.identify(id);
    },
    track(key: string, args: any) {
      mixpanel.track(key, args);
    },
  };
}
