import "@/styles/globals.css";
import { Inter, Fira_Code } from "next/font/google";
import { Analytics } from "@vercel/analytics/react";

import type { AppProps } from "next/app";

import { createBrowserSupabaseClient } from "@supabase/auth-helpers-nextjs";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { QueryClient, QueryClientProvider } from "react-query";
import analytics from "@/utils/analytics/analytics";
import { Toaster } from "react-hot-toast";
import Script from "next/script";

const queryClient = new QueryClient();

const inter = Inter({
  subsets: ["latin"],
  variable: "--font-inter",
});

export default function App({ Component, pageProps }: AppProps) {
  const [supabase] = useState(() => createBrowserSupabaseClient());
  const router = useRouter();

  supabase.auth.onAuthStateChange((event, session) => {
    if (event == "PASSWORD_RECOVERY") {
      return;
    }

    if (!session) {
      return router.push("/login");
    }

    // TODO: Move to express-session
    localStorage.setItem("accessToken", session.access_token);
  });

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID) {
      // @ts-expect-error
      window.$crisp = [];
      // @ts-expect-error
      window.$crisp.push(["safe", true]);
      // @ts-expect-error
      window.CRISP_WEBSITE_ID = process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID;
      (() => {
        const d = document;
        const s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.async = true;
        d.getElementsByTagName("body")[0].appendChild(s);
      })();
    }

    analytics.track("load_website");
  }, []);

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
        }
      `}</style>
      <Analytics />
      {process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID && (
        <Script id="clarity-script" strategy="afterInteractive">
          {`
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID}");
          `}
        </Script>
      )}
      <SessionContextProvider
        supabaseClient={supabase}
        initialSession={pageProps.initialSession}
      >
        <Toaster />
        <QueryClientProvider client={queryClient}>
          <main className={`${inter.variable} font-sans`}>
            <Component {...pageProps} />
          </main>
        </QueryClientProvider>
      </SessionContextProvider>
    </>
  );
}
